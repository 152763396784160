import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import FormSuccessPage from '../hanzo/pages/form-sucess'

const contactOk = () => {
    const data = useStaticQuery(graphql`
        query FormContactSubmittedSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Form_ContactSubmitted" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Helmet>
                <meta name='robots' content='noindex' />
                <meta name='robots' content='nofollow' />
            </Helmet>
            <section>
                <FormSuccessPage />
            </section>
        </Layout>
    )
}

export default contactOk
