import React from 'react'
import * as myStyles from './styles.module.scss'
import {
    formSuccessButtonLabel,
    formSuccessButtonLink,
    formSuccessDescription,
    formSuccessTitle,
} from './constants'
import ClickableArea from '../../lib/components/basics/clickable-area/component'

const FormSuccessPage = () => {
    const styles: any = myStyles

    return (
        <div className={styles.form_success_page}>
            <div className={styles.content}>
                <h5 className={styles.title}>{formSuccessTitle}</h5>
                <p className={styles.description}>{formSuccessDescription}</p>
                {formSuccessButtonLink && (
                    <ClickableArea href={formSuccessButtonLink} variant='primary-orange'>
                        {formSuccessButtonLabel}
                    </ClickableArea>
                )}
            </div>
        </div>
    )
}

export default FormSuccessPage
